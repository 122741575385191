.container-transcription{
display: flex;
height: 100%;
}

.transcription-values{
    width: 353px;
}

.transcription-values-audio{
    width: 100%;
    height: 63vh;
    /* padding-bottom: 100px !important; */
}

.audio-container-position{
position: absolute;
bottom: 10px;
}

.custom-width-small-screens{
    width: 85%;
}

.child-video-transcription{
    flex: 1;
}

.captions-default-style{
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 170.336%;
    display: inline !important;
}
.bg-color-video-caption{
    /* border-radius: 0px 10px 10px 10px; */
    border-top-right-radius:  13px;
    background: linear-gradient(0deg, #6C787E 0%, #6C787E 100%), #FFF;
}
.bg-color-audio-caption{
    /* background-color: white; */
}
.caption-color-video{
color: white;
}

.caption-color-audio{
    color: black;

}
.normal-color-video{
    color: black;

}
.normal-color-audio{
color:#8A8A8E
}
.custom-padding-container{
    overflow-y: scroll;
}
.custom-padding-container-audio{
    overflow-y: scroll;
}
.header-transcription{
    padding-top: 15px;
    color: #FFF;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.transcription-postiton-adjust{
    right: 360px;
}

.button-change-video{
    color: white;
    font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: normal;
display: flex;
align-items: center;
margin-top: 12px !important;
border-radius: 13px;
border: 1.5px solid var(--white, #FFF);
/* margin-right: 18px; */
}
.button-change-audio{
    color: black;
    font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: normal;
display: flex;
align-items: center;
margin-top: 12px !important;
border-radius: 13px;
border: 1.5px solid  #000;
/* margin-right: 18px; */
}

.custom-padding-container-transcription{
    padding-left: 20px;
    padding-right: 22px;

}

.custom-padding-container::-webkit-scrollbar {
    height: 0px;
    width: 0px;
 }

 .custom-padding-container-audio::-webkit-scrollbar {
    height: 0px;
    width: 0px;
 }

 .btn-secondary.dropdown-toggle::after {
    content: none; /* Remove the content from the pseudo-element */
  }

  .transcription-dropdown{
    position: absolute;
    left: 20px;
    height: 20px;
  }

  .dropdown-toggle:hover {
      background-color: none; /* Change this to the desired background color */
      color: #000; /* Change this to the desired text color */
      border-color: none; /* Change this to the desired border color */
  }
  
  /* Optionally, remove the shadow on hover */
  .dropdown-toggle:hover {
      box-shadow: none;
  }
  
  /* Optionally, remove the caret icon */
  .dropdown-toggle::after {
      content: none;
  }

  .transcription-captions{
    width: 250px;
    font-size: 12px;
font-style: normal;
font-weight: 400;
  }
  .transcription-caption-parent{
    left: -125px !important;
    top: 5px !important;
    border-radius: 12px;
background: var(--materials-regular, linear-gradient(0deg, #383838 0%, #383838 100%), rgba(179, 179, 179, 0.82));
background-blend-mode: color-dodge, normal;
box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.20);
backdrop-filter: blur(75px);
  }
  .each-cell-lang{
    display: flex;
    align-items: center;
    height: 44px;
color:#000;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 22px;
border-top: 0.5px solid rgba(17, 17, 17, 0.25);
  }

  .padding-custom-lang{
padding-left: 33px;
  }
  .select-lang-new{
    padding-left: 9px;
    padding-right: 9px;
  }

  .audio-caption-label{

  }
  .button-dropdown-custom{
    width: 115px;
    z-index: 999;
  }
  .controls-left ::cue {
    order: -1;
}

video::cue {
    color: white;
    font-size: 27px;
}

.btn-secondary:hover {
    border-color: none !important;
    background-color: none !important;

}

.secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
    background-color: none !important;  
     border-color: none !important;
}

.content-overflow-adjust{
    height: 70vh;
    overflow-x:scroll ;
}

.content-overflow-adjust::-webkit-scrollbar {
    height: 0px;
    width: 0px;
 }

 .flex-center{
    display: flex;
    align-items: center;
    justify-content: center;
 }