.parent-container-pdf{
    opacity: 0.3;
}
.view-pdf-button{
    position: absolute;
    left: 29%;
    top: 50%;
    border-radius: 8px;
    background-color: #043293;
    color: white;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 15px;
}
.relative-cont{
    position: relative;
} 
  .Example input,
  .Example button {
    font: inherit;
  }
  .Example header {
    background-color: #323639;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    padding: 20px;
    color: white;
  } 
  .Example header h1 {
    font-size: inherit;
    margin: 0;
  }
  .Example__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .Example__container__load {
    margin-top: 1em;
    color: white;
  }
  .Example__container__document {
    margin: 1em 0;
  }
  .Example__container__document .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .Example__container__document .react-pdf__Page {
    max-width: calc(100% - 2em);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    margin: 1em;
  }
  .Example__container__document .react-pdf__Page canvas {
    max-width: 100%;
    height: auto !important;
  }
  .Example__container__document .react-pdf__message {
    padding: 20px;
    color: white;
  }
.audio-player-custom-safari{
  width: 50% !important;
  margin: auto;
}
.audio-player-custom{
  width: 100% !important;
  margin: auto;
}
.content-box-text {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
  width: 80%;
  margin: auto;
  margin-top: 20px;
}
.div-container-button {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: right;
  width: 81.3%;
  margin: auto;
}
@media (min-width: 992px) {
  .div-container-button {
    width: 94%;
  }
}
@media (max-width: 576px) {
  .button-copy {
    width: max-content;
  }
}
.copy-button-image,
.copy-action {
  display: inline;
}
@media (max-width: 576px) {
  .copy-action {
    display: none;
  }
}
.media-head-text {
  border-radius: 10px;
  background: #FFF;
  color: black;
  padding: 25px !important;
  font-size: 18px;
}
@media (max-width: 576px) {
  .media-head-text {
    font-size: 15px;
  }
}
.media-wrapper {
  border-radius: 20px;
  overflow: hidden;
}
@media (max-width: 768px) {
  .media-wrapper {
    border-radius: 16px;
  }
}

.media-wrapper-pdf {
  border-top-left-radius: 20px; /* Adjust the radius as needed */
    border-top-right-radius: 20px; 

  overflow: hidden;
}
@media (max-width: 768px) {
  .media-wrapper-pdf {
    border-top-left-radius: 16px; /* Adjust the radius as needed */
    border-top-right-radius: 16px; 
  }
}
.media-head {
  background-color: rgba(0, 0, 0, 0.5);
  color: #FFF;
  padding: 12px 15px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
@media (max-width: 768px) {
  .media-head {
    padding: 10px 15px;
  }
}
.media-body {
  height: 660px;
  position: relative;
}
@media (max-width: 992px) {
  .media-body {
    height: 600px;
  }
}
@media (max-width: 768px) {
  .media-body {
    height: 550px;
  }
}
@media (max-width: 576px) {
  .media-body {
    height: 500px;
  }
}
@media (max-width: 480px) {
  .media-body {
    height: 400px;
  }
}
.media-body video{
  width: 100%;
  border-radius: 0px 0px 20px 20px;
  height: 100%;
  object-position: center;
  background-color: #000000;

}
.media-body img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.media-body iframe {
  width: 100%;
  height: 100%;
}
.media-body.audio-body {
  height: auto;
  text-align: center;
  padding: 15px;
  background: #f7f7f7;
}
.text-content {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 10px 5px;
}
.help-bottom {
  position: absolute;
  bottom: 20px;
  right: 20px;
  text-align: right;
}
.help-content {
  position: absolute;
  bottom: 62px;
  right: 0;
  white-space: pre;
}
.need-btn,
.reply-btn {
  background-color: #7f7f7f;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
.reply-btn:after {
  content: "";
  position: absolute;
  right: 14px;
  bottom: -10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 7px 0 7px;
  border-color: #7f7f7f transparent transparent transparent;
}
.content-box {
  padding: 35px 0;
  display: flex;
  justify-content: center;
  width: 80%;
  margin: auto;
}
.content-box-comment {
  padding: 35px 0;
  display: flex;
  justify-content: center;
  margin: auto;
}
@media (max-width: 576px) {
  .content-box {
    padding: 30px 0;
    display: flex;
    justify-content: center;
    width: 80%;
    margin: auto;
  }
}
 /* text viewer css start */
 .copy-container-button {
  display: flex;
  align-items: center;
  justify-content: end;
  justify-items: center;
  margin-bottom: 25px;
  height: 41px;
}
.button-copy {
  border-radius: 5px;
  background: #fff;
  outline: none !important;
  padding: 6px 20px;
  border: none;
  color: #043293;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  z-index: 10;
  border-radius: 29px;
  border: 1px solid #043293;
}
.copy-button-image {
  display: inline;
}
.copy-action {
  display: inline;
}
@media only screen and (max-width: 807px) {
  .copy-action {
    display: none;
 }
}
.header-text-view-notes {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 25px;
  color: var(--gray-color-new);
  text-align: center;
  padding-top: 18px;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.scrole-hight {
  max-height: 68vh;
  overflow-y: auto;
}
 /* text viewer css end*/
 /* Middle Content */
.content-box {
  padding: 35px 0;
  width: 80%;
  margin: auto;
}
@media screen and (max-width: 767px) {
  .content-box {
     padding: 30px 0;
     width: 80%;
     margin: auto;
  }
}

.content-box-pdf {
  padding-top: 65px;
}

@media screen and (max-width: 767px) {
  .content-box-pdf {
     padding-top: 40px;
  }
}
.center-block {
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  text-align: left;
}
.center-block h1,
.center-block h2 {
  margin-bottom: 0.5rem;
}
.center-block p {
  font-size: 21px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 24px;
}
.center-block .btn-primary {
  width: 100%;
  padding: 11px 20px;
}
.date-picker-control-div {
  position: relative;
}
.date-picker-icon {
  position: absolute;
  top: 6px;
  right: 5px;
  border: none;
  background: none;
}
.content-media-info-main-div {
  width: 100%;
  margin: auto;
}
.content-meida-title {
  text-align: left;
  font-weight: 700;
  font-size: 17px;
  color: #000000;
  margin-bottom: 15px;
}
.content-media-desc {
  text-align: left;
  width: 100%;
  height: 98px;
  background: rgba(224, 224, 224, 0.2);
  padding: 10px;
  overflow-wrap: break-word;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}
