/* The Modal (background) */
.modal {
   display: block; /* Hidden by default */
   position: fixed; /* Stay in place */
   z-index: 9999999999999 !important; /* Sit on top */
   padding-top: 15%; /* Location of the box */
   left: 0;
   top: 0;
   width: 100%; /* Full width */
   height: 100%; /* Full height */
   overflow: auto; /* Enable scroll if needed */
   background-color: rgb(0, 0, 0); /* Fallback color */
   background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
   position: relative;
   background-color: #fefefe;
   margin: auto;
   padding-top: 70px;
   padding-bottom: 20px;
   border: 1px solid #888;
   width: 666px;
   border-radius: 10px;
   height: 330px;
}

.margin-adjust{
   margin-bottom: 20px;
}

.modal-content-feedback{
   position: relative;
   background-color: #fefefe;
   margin: auto;
   padding: 20px;
   border: 1px solid #888;
   width: 666px;
   border-radius: 10px;
}

/* The Close Button */
.close-icon {
   position: absolute;
   font-size: 28px;
   right: 20px;
   top: 10px;
   cursor: pointer;
}

.icon-two {
   background-image: url("../../images/thumbsUpOri.svg");
   background-repeat: no-repeat;
   width: 70px;
   height: 76px;
}
.icon-two:hover {
   background-image: url("../../images/thumbsUp.svg");
}
.icon-three {
   background-image: url("../../images/thumbsDownori.svg");
   width: 70px;
   height: 76px;
   background-repeat: no-repeat;
}
.icon-three:hover {
   background-image: url("../../images/thumbsDown.svg");
}

.feedback {
    padding-top: 20px;
    padding-bottom: 20px;
   font-style: normal;
   font-weight: 600;
   font-size: 27px;
   line-height: 140%;
   /* identical to box height, or 38px */

   text-align: center;
   letter-spacing: 0.001em;

   /* Primary Blue */

   color: #043293;
}
.experience {
    width: 80%;
    margin: auto;
    padding-top: 20px;
    padding-bottom: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 140%;
    /* identical to box height, or 38px */
 
    text-align: center;
    letter-spacing: 0.001em;
 
    /* Primary Blue */
 
    color: #043293;
}

.experience-input{
   width: 80%;
   margin-left: auto;
   margin-right: auto;
   font-style: normal;
   font-weight: 600;
   font-size: 21px;
   line-height: 140%;
   /* identical to box height, or 38px */

   text-align: center;
   letter-spacing: 0.001em;

   /* Primary Blue */

   color: #043293;
}
.cont-input-boxx-button >textarea{
   width: 75%;
   outline: none;
   background: #F1F1F1;
   border: 1px solid #FF567A;
   border-radius: 8px;
   height: 130px;
   resize: none;
   padding-left: 8px;
   padding-right: 8px;
   margin-top: 5px;

}

.cont-input-boxx-button{
   margin-top: 10px;
}
.cont-input-boxx-button >button{
   background: #FF567A;
   border-radius: 5px;
   color: white;
   outline: none;
   border: none;
   margin-top: 20px;
   width: 170px;
   height: 40px;
}

.divHover{
    cursor: pointer;
    padding-bottom: 0px;
    width: 50%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-container-div{
    height: 185px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.cont-input-boxx-button{
   display: flex;
   flex-direction: column;
   align-items: center;
}
.thanks{
    height: 73px;
    width: 73px;
    margin-left: auto;
    margin-right: auto;
}
.sharing-response{
    padding-top: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px !important;
    line-height: 20px;
    text-align: center;
    
    color: #000000;
}

@media only screen and (max-width: 600px) {
    .modal-content {
      width: 90%;
   height: 380px;
   padding-top: 105px;
    }
    .modal-content-feedback{
      width: 90%;
    }
    .divHover{
        width: 70%;
       
    }
    .modal {
      padding-top: 50%;
   }
   .experience{
      width: 90%;
   }
   
  }