.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
   font-size: 12px;
   line-height: 2rem !important;
   width: 40px !important;
}
.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
   width: 45%;
}
.react-datepicker__month-dropdown-container.react-datepicker__month-dropdown-container--select select,
.react-datepicker__year-dropdown-container.react-datepicker__year-dropdown-container--select select {
   width: 100%;
   background: #ffffff;
   border-radius: 3px;
   height: 25px;
   font-size: 12px;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
   background-color: #0063e8 !important;
}

.react-datepicker__input-container {
   position: relative;
}
.react-datepicker__input-container {
   position: relative;
   display: inline-block;
}
.react-datepicker__input-container:after {
   content: "";
   position: absolute;
   display: inline-block;
   width: 20px;
   height: 20px;
   top: 7px;
   right: 10px;
   background-size: cover;
}
.react-datepicker__input-container::after {
   content: "";
   position: absolute;
   display: inline-block;
   width: 20px;
   height: 20px;
   top: 8px;
   right: 8px;
   background-size: cover;
}
.react-datepicker-popper {
   z-index: 2;
}
.react-datepicker-popper .react-datepicker {
   top: 15px;
}
